







import { Vue, Component } from "vue-property-decorator";


@Component({
    metaInfo() {
       return {
        title: 'About'
      }
   }
})

export default class About extends Vue {


}

